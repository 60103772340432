import React, { useEffect, useRef, useState } from 'react'
import { HtmlToReact } from '../../utils/utils'
import { ButtonType, NeedleButton } from '../../components/NeedleButton'
import { SubItem } from '../../components/DetailCardView/SubItem'
import { EventType } from '../HomeScreen/constants'
import { setupDataDisplay } from './functions'
import dialogStore, { DialogType } from '../../storeZustand/dialogStore'
import { Idea } from '../../utils/needleEnum'
import ShowAndHideButton from './ShowAndHideButton'
import ideasStore from '../../storeZustand/ideasStore'
interface IdeasDetailTopSummaryProps {
    currentIdeas: Idea | undefined
    ideasStatus: EventType
    isFailLoading: boolean
    isPreview?: boolean
}
export const IdeasDetailTopSummary = ({
    currentIdeas,
    ideasStatus,
    isFailLoading,
    isPreview = false,
}: IdeasDetailTopSummaryProps) => {
    const { currency } = ideasStore((state) => ({
        currency: state.currency,
    }))
    const [currentIdea, setCurrentIdea] = useState({
        title: '',
        description: '',
        revenueImpact: '',
        whyDoThis: '',
        app: '',
    })
    const [isShow, setIsShow] = useState<boolean>(false)
    const [isOpenTool, setIsOpenTool] = useState<boolean>(false)

    const dropdownRef = useRef<HTMLDivElement>(null)
    const { setIsOpen } = dialogStore((state) => ({
        setIsOpen: state.setIsOpen,
    }))

    const handleClickOutside = (event: any) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsOpenTool(false)
        }
    }

    useEffect(() => {
        if (currentIdeas) {
            setupDataDisplay(currentIdeas, setCurrentIdea)
        }
    }, [currentIdeas])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const openArchiveModal = (): void => {
        setIsOpen(true, DialogType.Archive)
    }

    const openMarkDoneModal = (): void => {
        setIsOpen(true, DialogType.MarkAsDone)
    }

    const openRestoreModal = (): void => {
        setIsOpen(true, DialogType.RestoreIdeas)
    }

    return (
        <div className=" bg-white rounded-lg mt-4 shadow-un-click-card relative">
            <div className="p-6">
                {ideasStatus !== EventType.Empty && (
                    <div className="pb-2 flex">
                        <SubItem
                            event={ideasStatus}
                            metadata={currentIdeas?.metadata}
                            dateSet={
                                ideasStatus === EventType.Done
                                    ? currentIdeas?.done_date
                                    : currentIdeas?.suggested_asset_delivery_date
                            }
                        />
                    </div>
                )}
                <div className="font-header-3 flex flex-row justify-between">
                    {isFailLoading && <div>Cannot access to this idea</div>}
                    <div>{HtmlToReact(currentIdea.title)}</div>
                    {ideasStatus === EventType.Empty && currentIdeas && (
                        <div
                            className="cursor-pointer flex h-6 relative pl-2"
                            onClick={() => setIsOpenTool(!isOpenTool)}
                            ref={dropdownRef}
                        >
                            {!isPreview && (
                                <div className="w-6 h-6 flex-shrink-0">
                                    <img
                                        className="h-6 w-6"
                                        alt={`small-top-menu-icon`}
                                        src="/assets/icons/small-menu-icon.svg"
                                    />
                                </div>
                            )}

                            {isOpenTool && (
                                <div
                                    className="absolute font-body-text flex flex-row bg-white border-beige-outline py-3 right-0 
                                    border rounded-lg top-7 items-center w-153px justify-center hover:bg-beige"
                                    onClick={openArchiveModal}
                                >
                                    <img
                                        className="h-5 w-5"
                                        alt={`archive-icon-icon`}
                                        src="/assets/icons/archive-icon.svg"
                                    />
                                    <div className="flex pl-2">
                                        Archive idea
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div
                    className="font-body-text pt-2 text-dark-blue custom-insize-html"
                    dangerouslySetInnerHTML={{
                        __html: currentIdea.description?.replace(/\n/g, '<br>'),
                    }}
                />
                {currentIdeas?.idea_revenue ? (
                    <div className="flex mt-6">
                        {/* {currentIdeas?.metadata?.idea_apps &&
                        currentIdeas.metadata.idea_apps.length > 0 && (
                            <div className="flex items-center justify-center mr-3">
                                <ListToolsConnect
                                    listTools={currentIdeas?.metadata}
                                />
                            </div>
                        )} */}
                        <div className="flex justify-center items-center rounded-xl border border-beige-outline px-3 py-2 text-dark-blue">
                            <div className="font-small-text-bold">
                                Approx. Impact:
                            </div>
                            <div className="font-small-text pl-1">
                                ~{currency}
                                {currentIdea.revenueImpact}
                            </div>
                        </div>
                    </div>
                ) : null}
                {ideasStatus === EventType.Archived && (
                    <NeedleButton
                        children={
                            <div className="flex flex-row items-center justify-center">
                                <img
                                    className="h-4 w-4"
                                    alt={`archive-icon-icon`}
                                    src="/assets/icons/restore-icon.svg"
                                />
                                <div className="pl-2">Restore idea</div>
                            </div>
                        }
                        isFull={true}
                        buttonType={ButtonType.White}
                        onClickFunction={openRestoreModal}
                        className="pt-6"
                    />
                )}
                {currentIdeas &&
                    ideasStatus !== EventType.Done &&
                    (ideasStatus === EventType.AssetReady ||
                        !currentIdeas?.concepts ||
                        (currentIdeas?.concepts &&
                            currentIdeas.concepts.length === 0)) && (
                        <NeedleButton
                            children={
                                <div className="flex flex-row items-center justify-center">
                                    <div className="pl-2">
                                        Mark this idea as Done
                                    </div>
                                </div>
                            }
                            isFull={true}
                            buttonType={ButtonType.Black}
                            onClickFunction={openMarkDoneModal}
                            className="pt-6"
                        />
                    )}
            </div>
            {currentIdea.whyDoThis && (
                <div>
                    <div className="bg-beige-outline h-1px w-full absolute left-0" />
                    <div className="p-6">
                        <div className="flex flex-row">
                            <div className="font-header-3 text-dark-blue">
                                Why do this?
                            </div>
                            <ShowAndHideButton
                                isShow={isShow}
                                setIsShow={setIsShow}
                            />
                        </div>
                        {isShow && (
                            <div
                                className="pt-4 font-body-text text-black"
                                dangerouslySetInnerHTML={{
                                    __html: currentIdea.whyDoThis,
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default IdeasDetailTopSummary
