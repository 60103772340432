import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PATH } from '../../constants/path'
import useUserData from '../../hooks/useUserData'
import { Idea, IdeaStatus } from '../../utils/needleEnum'
import { usePageTitle } from '../HomeScreen/component'
import { EventType } from '../HomeScreen/constants'
import IdeasDetailDialog from './Dialog/IdeasDetailDialog'
import IdeasDetailBottomConcepts from './IdeasDetailBottomConcepts/IdeasDetailBottomConcepts'
import IdeasDetailTopSummary from './IdeasDetailTopSummary'
enum BackButtonType {
    Ideas = 'Ideas',
    Actions = 'Actions',
}

interface PreviewMessage {
    source: 'needle-preview'
    type: 'PREVIEW_DATA'
    payload: Idea
}
export const IdeasDetailPagePreview = () => {
    usePageTitle('Needle - Idea Detail')
    const { workspace_id } = useParams()
    const [idea, setIdea] = useState<Idea | null>(null)
    const { data: userData } = useUserData(workspace_id)
    const isFailLoading = !idea
    const [ideasStatus, setIdeasStatus] = useState<EventType>(EventType.Empty)
    const [backButtonType, setBackButtonType] = useState<
        BackButtonType | undefined
    >(isFailLoading ? BackButtonType.Ideas : undefined)

    useEffect(() => {
        // Notify parent that we're ready to receive data
        window.parent.postMessage({ type: 'PREVIEW_READY' }, '*')

        const handleMessage = (event: MessageEvent) => {
            // Log incoming message for debugging
            console.log('Received message:', event.data)

            // Type guard to check if the message has our specific format
            const isNeedlePreviewMessage = (
                data: any
            ): data is PreviewMessage => {
                return (
                    data?.source === 'needle-preview' &&
                    data?.type === 'PREVIEW_DATA' &&
                    data?.payload
                )
            }

            if (isNeedlePreviewMessage(event.data)) {
                console.log(
                    'Valid preview message received:',
                    event.data.payload
                )
                setIdea(event.data.payload)
            }
        }

        window.addEventListener('message', handleMessage)

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    const setupIdeasStatus = (currentIdeas: Idea): void => {
        switch (currentIdeas.status) {
            case IdeaStatus.done:
                setIdeasStatus(EventType.Done)
                setBackButtonType(BackButtonType.Actions)
                break
            case IdeaStatus.needle_is_working:
                setIdeasStatus(EventType.Doing)
                setBackButtonType(BackButtonType.Actions)
                break
            case IdeaStatus.archived:
                setIdeasStatus(EventType.Archived)
                setBackButtonType(BackButtonType.Ideas)
                break
            case IdeaStatus.blocked:
                setIdeasStatus(EventType.Blocked)
                setBackButtonType(BackButtonType.Actions)
                break
            case IdeaStatus.new_asset:
            case IdeaStatus.read_asset:
                setIdeasStatus(EventType.AssetReady)
                setBackButtonType(BackButtonType.Actions)
                break
            case IdeaStatus.new:
            case IdeaStatus.read:
                setIdeasStatus(EventType.Empty)
                setBackButtonType(BackButtonType.Ideas)
                break
            default:
                setIdeasStatus(EventType.Empty)
        }
    }

    useEffect(() => {
        if (idea) {
            setupIdeasStatus(idea)
        }
    }, [idea])

    if (isFailLoading) {
        return <div>Loading...</div>
    }

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue flex flex-col items-center">
            <IdeasDetailDialog
                userData={userData}
                id={idea?.id ? idea.id : ''}
            />
            <div className="w-680px mt-15px">
                <IdeasDetailTopSummary
                    currentIdeas={idea!!}
                    ideasStatus={ideasStatus}
                    isFailLoading={isFailLoading}
                    isPreview
                />
                {idea?.concepts && idea?.concepts.length > 0 && (
                    <div>
                        <div className="font-header-3 pt-8">Concepts</div>
                        {idea.concepts.map((concept, index) => (
                            <IdeasDetailBottomConcepts
                                concept={concept}
                                ideasStatus={ideasStatus}
                                key={concept.title + index}
                                idea={idea}
                                isPreview
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default IdeasDetailPagePreview
